import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  // Customizable Area Start
  route: any;

  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: string;
  donationDetails: any;
  userImage: string;
  selectedAmount: string;
  processingFee: string;
  churchFee: string;

  calculateFee: boolean;
  totalAmount: string;
  showPaymentMethodModal: boolean,
  showCardModal: boolean,

  showPaymentModal: boolean,
  showSuccessModal: boolean

  cardEmail: string;
  cardFirstname: string;
  cardLastname: string;
  cardNumber: string;
  cardDate: string;
  cardCVV: string;
  cardName: string;
  cardCountry: string;
  cardPostalcode: string;

  showCountryPicker: boolean;

  useAsBillingName: boolean;
  saveCard: boolean,
  countryList: any
  countryListHolder: any;
  isLoading: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}


export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start

  addDonationPaymentID: string = "";
  getCountriesID: string = "";
  getDonationID:string =''
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage)
    ];

    this.state = {

      token: "",
      donationDetails: [],
      userImage: "",
      selectedAmount: "0",
      processingFee: "0",
     churchFee: "0",

      totalAmount: "0",
      showPaymentMethodModal: false,
      showCardModal: false,

      showPaymentModal: false,
      showSuccessModal: false,
      calculateFee: false,

      cardEmail: "",
      cardFirstname: "",
      cardLastname: "",
      cardNumber: "",
      cardDate: "",
      cardCVV: "",
      cardName: "",
      cardCountry: "",
      cardPostalcode: "",

      useAsBillingName: false,
      saveCard: false,

      showCountryPicker: false,
      countryList: [],
      countryListHolder: [],
      isLoading:false

    };


    this.onScreenLoad = this.onScreenLoad.bind(this);
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.onScreenLoad();
  }

  async onScreenLoad() {
    // const token = await getStorageData("token")
    const token = "eyJhbGciOiJIUzUxMiJ9.eyJpZCI6ODI1LCJleHAiOjE3NzI1NTc1MzUsInRva2VuX3R5cGUiOiJsb2dpbiJ9.K3pLUFPKrVuATdxxU346-xnCsdR524_jMHa8z96QsNi8XoMpagjCMAoPMerBu4LDzMCtXBz9OOPFjHhp2C1kfA"

    const donationId = await getStorageData('donationId')
    let userImage = await getStorageData('userImage')


    const details = this?.props?.route?.params?.item
    let data
    if(details) data= await JSON.parse(details)
    const finalId=data?.id ??donationId 
    this.setState({ token: token, userImage: userImage },()=>this.getDonationDetails(2181))
    this.getCountries();
  }
  getDonationDetails( id : number) {
    this.setState({isLoading: true})
    const header = {
      token:this.state.token,
    };

    const requestDonationMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDonationID = requestDonationMessage.messageId;

    requestDonationMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      
      `${configJSON.singlePostAPI}${id}`
    );
   
    requestDonationMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestDonationMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestDonationMessage.id, requestDonationMessage);
  }

  

  // updateCalculations(amount: string) {
  //   let processingFee = 0;
  //   let churchFee= 0;
  //   let totalAmount = 0;

  //   if (amount && amount.trim() !== "") {
  //     const numericAmount = parseFloat(amount);

  //     if (this.state.calculateFee) {
  //       processingFee = numericAmount * 0.10;
  //       churchFee = numericAmount * 0.10;

  //       totalAmount = numericAmount + processingFee + churchFee;
  //     } else {
  //       totalAmount = numericAmount;
  //     }
  //   }

  //   this.setState({
  //     selectedAmount: amount,
  //     processingFee: (Math.round(processingFee * 100) / 100).toFixed(2),
  //     churchFee: (Math.round(churchFee * 100) / 100).toFixed(2),

  //     totalAmount: (Math.round(totalAmount * 100) / 100).toFixed(2)
  //   })
  // }

  updateCalculations(amount: string) {
    let churchFee = 0;
    let processingFee = 0;
    let totalAmount = 0;
  
    if (amount && amount.trim() !== "") {
      const numericAmount = parseFloat(amount);
  
      // Church Fee is always applied (Mandatory 10% of subtotal)
      churchFee = numericAmount * 0.10;
  
      // Processing Fee is optional (applied only if checkbox is checked)
      if (this.state.calculateFee) {
        processingFee = numericAmount * 0.10;
      }
  
      totalAmount = numericAmount + churchFee + processingFee;
    }
  
    this.setState({
      selectedAmount: amount,
      churchFee: (Math.round(churchFee * 100) / 100).toFixed(2),
      processingFee: (Math.round(processingFee * 100) / 100).toFixed(2),
      totalAmount: (Math.round(totalAmount * 100) / 100).toFixed(2),
    });
  }
  
  toggleProcessingFee() {
    this.setState(
      (prevState) => ({ calculateFee: !prevState.calculateFee }),
      () => this.updateCalculations(this.state.selectedAmount)
    );
  }
  

  donateSecurely() {
    if (this.state.selectedAmount && this.state.selectedAmount.trim() !== "" && parseFloat(this.state.selectedAmount) > 0) {
      this.setState({ showPaymentMethodModal: true })
    }
    else {
      this.showAlert("","Please enter select or enter valid amount");
    }
  }

  saveCardandDonate() {

    // if (this.state.cardEmail.length < 1) {
    //   this.showAlert("","Please enter valid email");
    //   return false;
    // }
    // else if (this.state.cardFirstname.length < 1) {
    //   this.showAlert("","Please enter valid first name");
    //   return false;
    // }
    // else if (this.state.cardLastname.length < 1) {
    //   this.showAlert("","Please enter valid last name");
    //   return false;
    // }
    // else if (this.state.cardNumber.length < 12 || this.state.cardNumber.length > 16) {
    //   this.showAlert("","Please enter valid card number");
    //   return false;
    // }
    // else if (this.state.cardDate.length < 1) {
    //   this.showAlert("","Please enter valid card date");
    //   return false;
    // }
    // else if (this.state.cardCVV.length < 1) {
    //   this.showAlert("","Please enter valid CVV");
    //   return false;
    // }
    // else if (this.state.cardName.length < 1) {
    //   this.showAlert("","Please enter valid Card name");
    //   return false;
    // }
    // else if (this.state.cardCountry.length < 1) {
    //   this.showAlert("","Please enter valid Country");
    //   return false;
    // }
    // else {
    //   this.setState({ showPaymentModal: true, showCardModal : false })
    // }
    this.setState({ showPaymentModal: true, showCardModal : false })

  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    const apiRequestCallid = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
    const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
    this.setState({isLoading:false})
    if (apiRequestCallid === this.addDonationPaymentID) {


      // if (!responseJson.error) {
        this.setState({ showSuccessModal: true })
        // setTimeout(() => {
        //   this.props.navigation.navigate('Bottomtab');
        // }, 3000);
      // }

    }

    if (apiRequestCallid === this.getCountriesID) {
      if (!responseJson.errors) {
        this.setState({ countryList: responseJson.countries, countryListHolder: responseJson.countries })
      }
    }
    if (apiRequestCallid === this.getDonationID) {
      if (!responseJson.errors) {
        this.setState({ donationDetails:responseJson.data})

      }
    }


  }



  getCountries(): boolean {
    const header = {
      "Content-Type": configJSON.getCountriesContentType,
      token: this.state.token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    this.getCountriesID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getCountriesEndpoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCountriesMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  }


  maskCardNumber = (cardNumber: string) => {
    if (typeof cardNumber !== 'string' || cardNumber.length < 9) {
      return 'Invalid card number';
    }
    const firstFour = cardNumber.slice(0, 4);
    const lastFour = cardNumber.slice(-4);
    const maskedNumber = cardNumber.slice(4, -4).replace(/\d/g, '*');
    return `${firstFour} ${maskedNumber} ${lastFour}`;
  };


  searchCountries(text: string) {
    const newData = this.state.countryListHolder?.filter((item: any) => {
      const itemData =
        item.name.toUpperCase() + item.name.toUpperCase();

      const textData = text.toUpperCase();
      return itemData.indexOf(textData) > -1;
    });
    this.setState({
      countryList: newData,
    });
  }

  navigateToStripe() {
    this.setState({ showPaymentMethodModal : false })
    this.setState({ showCardModal : true })


    // this.props.navigation.navigate('StripePaymentsComponentView', {
    //   donationID: this.state.donationDetails.id,
    //   amount: this.state.selectedAmount,
    //   processingFee: this.state.processingFee,
    //   totalAmount: this.state.totalAmount
    // })
  }

  createDonationPayment() {
    this.setState({ showSuccessModal: true })
    // const navStripePayments: Message = new Message(getName(MessageEnum.NavigationMessage))
    // navStripePayments.addData(getName(MessageEnum.NavigationTargetMessage), 'StripePayments');
    // navStripePayments.addData(getName(MessageEnum.NavigationPropsMessage), this.props)
    // this.send(navStripePayments);
  }




}


// Customizable Area End
