import React from "react";
import LandingPageController, { Props } from "./LandingPageController";
import {
  Box,
  Button,
  TextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Card,
  CardContent,
  Avatar,
  Divider,
  LinearProgress,
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Checkbox,
  FormControlLabel,
} from "@material-ui/core";
import { ArrowBack, CreditCard, Lock, AttachMoney, CheckBox, CheckBoxOutlineBlank,  } from "@material-ui/icons";
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import moment from "moment";
import { getPrimaryColor, getSecondaryColor } from "../../../components/src/GlobalStyles";


export default class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
  }

  renderAmountButton = (amount: string) => {
    return (
      <Button
        data-testid={"amountBtn" + amount}
        variant={this.state.selectedAmount === amount ? "contained" : "outlined"}
        style={{
          background: this.state.selectedAmount === amount ? `linear-gradient(to right, ${getPrimaryColor()}, ${getSecondaryColor()})` : "#fff",
          color: this.state.selectedAmount === amount ? "#fff" : "#000",
          borderRadius: 30,
          height: 48,
          width: 80,
        }}
        onClick={() => this.updateCalculations(amount)}
      >
        ${amount}
      </Button>
    );
  };

  renderAmountSection = () => {
    return (<Box mt={2}> <Box display="flex" justifyContent="space-between"> <Typography variant="body1">Subtotal</Typography> <Typography variant="body1">${this.state.selectedAmount || 0}</Typography> </Box>
      {/* Mandatory church donation fee */} 
      <Box display="flex" justifyContent="space-between" mt={1}> <Typography variant="body1"><Checkbox checked={true} data-testid="processingCheckbox" />Support your church*</Typography> <Typography variant="body1">${this.state.churchFee}</Typography> </Box>

      {/* Optional processing fee checkbox */}
      {/* <FormControlLabel control={<Checkbox checked={true} data-testid="processingCheckbox" />}
        label="Support your church*" /> */}

      {/* Optional processing fee checkbox */}
      <Box display="flex" justifyContent="space-between" mt={1}> <Typography variant="body1"><Checkbox checked={this.state.calculateFee} onChange={() => this.toggleProcessingFee()} data-testid="processingCheckbox" />Processing fee</Typography> <Typography variant="body1">${this.state.churchFee}</Typography> </Box>



      {/* <FormControlLabel control={<Checkbox checked={this.state.calculateFee} onChange={() => this.toggleProcessingFee()} data-testid="processingCheckbox" />}
        label="Cover processing fees (10%)" /> */}
      {/* Show processing fee only if checkbox is checked */}
      {/* {this.state.calculateFee && (<Box display="flex" justifyContent="space-between" mt={1}>
        <Typography variant="body1">Processing fee</Typography>
        <Typography variant="body1">${this.state.processingFee}</Typography>
      </Box>)} */}
      <Divider style={{ margin: "10px 0" }} /> <Box display="flex" justifyContent="space-between" fontWeight="bold"> <Typography variant="h6">Total</Typography> <Typography variant="h6">${this.state.totalAmount}</Typography> </Box> </Box>);
  };



  
  renderPaymentMethodModal = () => {
    return (
      <Dialog
        open={this.state.showPaymentMethodModal}
        onClose={() => this.setState({ showPaymentMethodModal: false })}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <IconButton onClick={() => this.setState({ showPaymentMethodModal: false })}>
            <ArrowBack />
          </IconButton>
          Payment Method
        </DialogTitle>
        <DialogContent>
          <Box textAlign="center">
            <Avatar src={this.state.userImage} style={{ width: 80, height: 80, margin: "0 auto" }} />
            <Typography variant="h6">
              Get a reward by helping others
            </Typography>
            <Typography variant="body2">Finish your donation and earn a reward</Typography>
          </Box>

          <Button
            data-testid="cardBtn"
            onClick={() => this.navigateToStripe()}
            fullWidth
            variant="outlined"
            startIcon={<CreditCard />}
            style={{ marginTop: 20 }}
          >
            Credit or Debit Card
          </Button>

          {this.renderAmountSection()}
        </DialogContent>
      </Dialog>
    );
  };

  renderCardModal = () => {
    return (
      <Dialog
        open={this.state.showCardModal}
        onClose={() => this.setState({ showCardModal: false, showPaymentMethodModal: true })}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <IconButton onClick={() => this.setState({ showCardModal: false, showPaymentMethodModal: true })}>
            <ArrowBack />
          </IconButton>
          Payment Method
        </DialogTitle>
        <DialogContent>
          <TextField
            data-testid="inputEmail"
            fullWidth
            label="Email address"
            variant="outlined"
            margin="dense"
            onChange={(e) => this.setState({ cardEmail: e.target.value })}
          />
          <TextField
            data-testid="inputFirstName"
            fullWidth
            label="First name"
            variant="outlined"
            margin="dense"
            onChange={(e) => this.setState({ cardFirstname: e.target.value })}
          />
          <TextField
            data-testid="inputLastName"
            fullWidth
            label="Last name"
            variant="outlined"
            margin="dense"
            onChange={(e) => this.setState({ cardLastname: e.target.value })}
          />

          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkBilling"
                checked={this.state.useAsBillingName}
                onChange={() => this.setState({ useAsBillingName: !this.state.useAsBillingName })}
              />
            }
            label="Use as billing name"
          />

          <TextField
            data-testid="inputCardNumber"
            fullWidth
            label="Card number"
            variant="outlined"
            margin="dense"
            onChange={(e) => this.setState({ cardNumber: e.target.value })}
          />

          <Box display="flex" justifyContent="space-between">
            <TextField
              data-testid="inputCardDate"
              label="MM / YY"
              variant="outlined"
              margin="dense"
              style={{ width: "48%" }}
              onChange={(e) => this.setState({ cardDate: e.target.value })}
            />
            <TextField
              data-testid="inputCVV"
              label="CVV"
              type="password"
              variant="outlined"
              margin="dense"
              style={{ width: "48%" }}
              onChange={(e) => this.setState({ cardCVV: e.target.value })}
            />
          </Box>

          <TextField
            data-testid="inputCardName"
            fullWidth
            label="Name on card"
            variant="outlined"
            margin="dense"
            onChange={(e) => this.setState({ cardName: e.target.value })}
          />

          <FormControlLabel
            control={
              <Checkbox
                data-testid="checkSaveCard"
                checked={this.state.saveCard}
                onChange={() => this.setState({ saveCard: !this.state.saveCard })}
              />
            }
            label="Save card for future donations"
          />
        </DialogContent>
        <DialogActions>
          <Button data-testid="btnDonateNow" onClick={() => this.saveCardandDonate()} color="primary" variant="contained">
            Donate Now
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderPaymentSummaryModal = () => {
    return (
      <Dialog
        open={this.state.showPaymentModal}
        onClose={() => this.setState({ showPaymentModal: false, showCardModal: true })}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>
          <IconButton onClick={() => this.setState({ showPaymentModal: false, showCardModal: true })}>
            <ArrowBack />
          </IconButton>
          Payment Summary
        </DialogTitle>
        <DialogContent>
          <Typography variant="subtitle1">Email</Typography>
          <Typography variant="body2">{this.state.cardEmail}</Typography>

          <Typography variant="subtitle1" >First name and Last name</Typography>
          <Typography variant="body2">{this.state.cardFirstname} {this.state.cardLastname}</Typography>

          <Divider style={{ margin: "10px 0" }} />

          {this.renderAmountSection()}

          <Typography variant="subtitle1" >Payment method</Typography>
          <Typography variant="body2">{this.maskCardNumber(this.state.cardNumber)}</Typography>

          <Box display="flex" justifyContent="space-between" mt={3}>
            <Typography>Transaction Date</Typography>
            <Typography>{moment().format("MM/DD/YY")}</Typography>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button data-testid="createDonation" onClick={() => this.createDonationPayment()} color="primary" variant="contained">
            Pay Now
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  renderSuccessModal = () => {
    return (
      <Dialog
        open={this.state.showSuccessModal}
        onClose={() => this.setState({ showSuccessModal: false })}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Thank You for Sharing!</DialogTitle>
        <DialogContent>
          <Typography variant="body2" align="center">
            You help others and get rewards for your generous actions.
          </Typography>

          <Box display="flex" justifyContent="center" mt={2}>
            <Typography variant="body1" color="primary">See your Donations {'>'}</Typography>
          </Box>
        </DialogContent>
      </Dialog>
    );
  };


  render() {
    return (
      <Box p={3}>
        {/* Header */}
        <Box display="flex" alignItems="center" mt={4}>
          <IconButton
            data-testid="goBackBtn"
            onClick={() => { this.props.navigation.goBack(); }}
            style={{ width: "15%" }}
          >
            <ArrowBack fontSize="large" />
          </IconButton>
          <Typography
            variant="h5"
            style={{ flexGrow: 1, textAlign: "center", fontWeight: "bold" }}
          >
            Donate
          </Typography>
        </Box>

        {/* Donation Details */}
        <Card
          data-testid="navigationdetails"
          style={{
            width: "95%",
            borderRadius: 17,
            backgroundColor: "#fff",
            margin: "10px auto",
            paddingBottom: 10,
          }}
        >
          <CardContent>
            {this.state.donationDetails?.attributes?.images?.length > 0 && (
              <img
                src={this.state.donationDetails?.attributes?.images[0]?.url || "https://as2.ftcdn.net/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"}
                alt="Donation"
                style={{ width: "100%", height: 230, borderRadius: 20 }}
              />
            )}
            <Box display="flex" alignItems="center" mt={0}>
              {/* <Lock color="disabled" /> */}
              <Typography variant="body2" style={{ marginLeft: 8, fontWeight: "bold" }}>
                Donation title Lorem ipsum dolor
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" mt={0}>
              {/* <Lock color="disabled" /> */}
              <Typography variant="body2" style={{ marginLeft: 8 }}>
                Donation description Lorem ipsum dolor
              </Typography>
            </Box>

            <Box mt={1} display="flex" alignItems="center">
              <Avatar
                src={this.state.donationDetails?.attributes?.user_image || "https://as2.ftcdn.net/jpg/02/44/42/79/1000_F_244427911_aoHHulebtYy4wLpncBBuWqCTNFKolcCB.jpg"}
              />
              <Box ml={2}>
                <Typography variant="subtitle1">
                  {this.state.donationDetails?.attributes?.name || "For Good Cause"}
                </Typography>
                <Typography variant="caption" color="textSecondary">
                  {moment(this.state.donationDetails?.attributes?.created_at).format("MMM. DD, YYYY") || "20-20-2020"}
                </Typography>
              </Box>
            </Box>

            <LinearProgress
              style={{ marginTop: 15, width: "95%", alignSelf: "center" }}
              variant="determinate"
              value={(this.state.donationDetails?.attributes?.amount_raised ?? 0) / 100}
            />

            <Box display="flex" justifyContent="space-between" mt={1}>
              <Typography variant="body1">
                $ {this.state.donationDetails?.attributes?.amount_raised || 0} raised
              </Typography>
              <Typography variant="body1">
                $ {this.state.donationDetails?.attributes?.targeted_amount || 0} goal
              </Typography>
            </Box>
            <Divider style={{ margin: "10px 0" }} />
            {/* Amount Selection */}
            <Box mt={3} display="flex" justifyContent="space-evenly">
              {this.renderAmountButton("1")}
              {this.renderAmountButton("5")}
              {this.renderAmountButton("10")}
              {this.renderAmountButton("20")}
            </Box>

            {/* New Checkboxes 
        <Box mt={2}> <FormControlLabel control={<Checkbox checked={this.state.churchFee ? true : false} onChange={() => this.setState({churchFee: "10"})} data-testid="supportChurchCheckbox" />} label="Support your church and Coinania (10%)" /> <FormControlLabel control={<Checkbox checked={this.state.processingFee ? true : false} onChange={() => this.setState({processingFee: "10"})} data-testid="processingCheckbox" />} label="Cover processing fees (10%)" /> </Box> */}



            <Box mt={3}>
              <TextField
                data-testid="inputOtherAmount"
                fullWidth
                variant="outlined"
                placeholder="Other amount"
                type="number"
                value={`${this.state.selectedAmount}`}
                onChange={(e) => this.updateCalculations(e.target.value)}
              />
            </Box>

            {this.renderAmountSection()}
          </CardContent>
        </Card>


        {/* {this.renderAmountSection()} */}

        {/* Secure Checkout Message */}
        <Box display="flex" alignItems="center" mt={3}>
          {/* <Lock color="disabled" /> */}
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            *Support your church with a 10% Processing fee. Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Typography>
        </Box>
        {/* Donate Button */}
        <Button
          data-testid="btnDonateSecurely"
          fullWidth
          variant="contained"
          style={{
            background: `linear-gradient(to right, ${getPrimaryColor()}, ${getSecondaryColor()})`,
            color: "#fff",
            marginTop: 20,
            padding: 12,
          }}
          onClick={() => this.donateSecurely()}
        >
          Donate Securely
        </Button>

        {/* Secure Checkout Message */}
        <Box display="flex" alignItems="center" mt={3}>
          <Lock color="disabled" />
          <Typography variant="body2" style={{ marginLeft: 8 }}>
            Secure checkout with data encryption
          </Typography>
        </Box>

               {/* Render Modals */}
               {this.renderPaymentMethodModal()}
        {this.renderCardModal()}
        {this.renderPaymentSummaryModal()}
        {this.renderSuccessModal()}

      </Box>
    );
  }
}



