Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";

exports.btnExampleTitle = "CLICK ME";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.getPostsURL = "bx_block_posts/posts/";
exports.txtInputPlaceholder = "Enter Text";
exports.labelTitleText = "DonationPayments";
exports.labelBodyText = "DonationPayments Body";
exports.getAnimojiEndPoint = "/bx_block_animoji/animojis"
exports.btnExampleTitle = "CLICK ME";

exports.addDonationPaymentMethodType = "POST";
exports.addDonationPaymentContentType = "multipart/form-data";
exports.addDonationEndpoint = "bx_block_posts/donor_pay_now";
exports.singlePostAPI = "bx_block_posts/show?post_id=";

exports.getCountriesMethodType = "GET";
exports.getCountriesContentType = "application/json";
exports.getCountriesEndpoint = "bx_block_admin/manage_attributes/get_countries";


exports.shareMethodType = "POST";
exports.shareContentType = "multipart/form-data";
exports.shareEndpoint = "bx_block_posts/create_post";

exports.pendingDonationMethodType = "GET";
exports.pendingDonationContentType = "application/json";
exports.pendingDonationEndpoint = "bx_block_posts/pending_donation";


exports.updateDonationMethodType = "PUT";
exports.updateDonationContentType = "multipart/form-data";
exports.updateDonationEndpoint = "bx_block_posts/update_post";



exports.deleteDonationMethodType = "DELETE";
exports.deleteDonationContentType = "multipart/form-data";
exports.deleteDonationEndpoint = "bx_block_posts/destroy";

exports.termsAndConditionsEndpoint = "bx_block_terms_and_conditions/terms_and_conditions";
exports.deleteBtnTxt = "Delete Donation";
exports.cancelBtnTxt = "Cancel";


// Customizable Area End